<template>
    <div id="questions-wrapper">
        <ul id="nav" class="nav nav-tabs mb-3">
            <li
                    v-for="tab in tabs"
                    :key="tab.name"
                    :class="['nav-item', { active: currentTab.name === tab.name }]"
                    @click="changeTab(tab)">
                <a class="nav-link" :class="[{ active: currentTab.name === tab.name }]">
                    <span>{{ tab.name }}</span>
                    <span class="counter" v-if="tab.id === 1 && topicList[0]">{{ topicList[0].my_count}}</span>
                    <span class="counter" v-if="tab.id === 2 && topicList[0]">{{ topicList[0].from_friends_count}}</span>
                </a>
            </li>
        </ul>
        <search-filter :show-error="true" :preload-data="preloadSearchData" :placeholderText="'Поиск вопросов'" :items="arr" @filtered="filteredList" class="search-filter" :is-server-side="true" @onChange="onSearchChange" ref="search"></search-filter>

        <div class="page-content">
            <div class="component-wrapper">
                <div class="add-personal-question" v-if="currentTab.id === 1">
                    <div class="btns-wrapper" v-on:click="showPersonalQuestionsModal">
                        <span class="plus-btn">+</span>
                        <span class="title-btn">Добавить персональный вопрос</span>
                    </div>
                </div>
                <component :is="currentTab.activeComp" :ref="currentTab.activeComp" :topicId="activeTopicId" :searchStr="search_str" :activeSearch="active_search"></component>
            </div>
            <right-sidebar :topicList="this.topicList" :activeTab="currentTab.id" :questionType="8"></right-sidebar>
        </div>

        <!--<div class="page-content" v-if="currentTab.id === 1">
            <div class="add-personal-question">
                <div class="btns-wrapper" v-on:click="showPersonalQuestionsModal">
                    <span class="plus-btn">+</span>
                    <span class="title-btn">Добавить персональный вопрос</span>
                </div>
            </div>
            <my-pq ref="my-pq" :topicId="activeTopicId" @onItemEdit="refreshPQ" :searchStr="search_str"></my-pq>
        </div>
        <div class="page-content" v-if="currentTab.id === 2">
            <inner-circle-pq ref="inner-circle-pq" :topicId="activeTopicId" :searchStr="search_str"></inner-circle-pq>
        </div>-->

        <!--<right-sidebar :topicList="this.topicList" :activeTab="currentTab.id" :questionType="8"></right-sidebar>-->
        <create-personal-question-modal ref="createPersonalQuestionRef" @refreshData="refreshPQ()" :custom-visibility-list="customVisibilityList"></create-personal-question-modal>
    </div>
</template>

<script>
    import searchFilter from '../../../components/_shared/SearchFilter';
    import QuestionsRightSidebar from "../../../components/questions/QuestionsRightSidebar";
    import createPersonalQuestionModal from "../../../components/_shared/modals/_CreatePersonalQuestionModal";
    import MyPq from "../../../components/questions/personal-questions/myPQ";
    import InnerCirclePq from "../../../components/questions/personal-questions/innerCircle";
    import EventBus from "../../../_helpers/event-bus";

    export default {
        name: 'personal-questions',
        data () {
            return {
                arr: [],
                tabs: [
                    {
                        name: 'МОИ',
                        id: 1,
                        activeComp: 'my-pq'
                    }, {
                        name: 'МОИХ ДРУЗЕЙ Up&Up',
                        id: 2,
                        activeComp: 'inner-circle-pq'
                    }
                ],
                customVisibilityList: [
                    {
                        description: 'Друзья Up&Up',
                        class: null,
                        type: 1
                    },
                    {
                        description: 'Выбранные пользователи',
                        class: null,
                        type: 2
                    }
                ],
                currentTab: null,
                topicList: [],
                search_str: '',
                targetUser: null,
                active_search: false,
                preloadSearchData: {search_str: '', active: false}
            }
        },
        components: {
            InnerCirclePq,
            MyPq,
            'right-sidebar': QuestionsRightSidebar,
            searchFilter,
            'create-personal-question-modal': createPersonalQuestionModal
        },
        methods: {
            refreshPQ() {
                this.$refs[this.currentTab.activeComp].resetPage();
                this.$refs[this.currentTab.activeComp].getQuestions(this.activeTopicId);
                this.getTopicList();
            },
            showPersonalQuestionsModal() {
                this.$refs.createPersonalQuestionRef.showModal();
            },
            getTopicList() {
                this.$store.dispatch('question/getTopics', 8);
            },
            changeTab(tab) {
                this.currentTab = tab;
                /*if (this.$refs.search) {
                    this.$refs.search.remoteClearSearch(true);
                }*/
                setTimeout(() => {
                    if (this.$refs.search.activeSearch && this.search_str.length > 0) {
                        console.log('_______________________________________________________________________________________');
                        this.$refs[this.currentTab.activeComp].resetPage(this.activeTopicId);
                        this.$refs[this.currentTab.activeComp].searchQuestions(this.activeTopicId, this.search_str);
                    }
                }, 0);
                this.$forceUpdate();
            },
            onSearchChange(data) {
                this.search_str = data;
                this.$store.dispatch('question/setSearch', {active: this.active_search, str: data});
            },
            filteredList (data) {
                this.search_str = data.searchStr;
                this.$refs[this.currentTab.activeComp].resetPage(this.activeTopicId);
                if (this.search_str.length > 0) {
                    this.active_search = true;
                    this.$store.dispatch('question/setSearch', {active: this.active_search, str: this.search_str});
                    this.$refs[this.currentTab.activeComp].searchQuestions(this.activeTopicId, this.search_str);
                } else {
                    this.active_search = false;
                    this.$store.dispatch('question/setSearch', {active: this.active_search, str: this.search_str});
                    this.$refs[this.currentTab.activeComp].getQuestions(this.activeTopicId);
                }

            },
        },
        computed: {
            activeSearch() {
                return this.$store.state.question.search;
            },
            topics() {
                return this.$store.state.question.topic_list_personal;
            },
            activeTopicId() {
                return this.$store.state.question.active_personal_topic_id
            }

        },
        created() {
            if (this.activeSearch.str.length > 0) {
                this.search_str = this.activeSearch.str;
                this.active_search = this.activeSearch.active;
                this.preloadSearchData = {search_str: this.activeSearch.str, active: this.activeSearch.active};
            }
            this.getTopicList();
            (this.$route.query.tab !== undefined) ? this.changeTab(this.tabs[this.$route.query.tab]) : this.changeTab(this.tabs[0]);
            this.targetUser = this.$route.query.user_id;
            EventBus.$on('loadMoreQuestions', (data) => {
                this.$refs[this.currentTab.activeComp].getQuestions(this.activeTopicId);
            });
            //this.loadListenerOn();
        },
        mounted () {},
        beforeDestroy() {
            EventBus.$off('loadMoreQuestions');
            //this.loadListenerOff()
        },
        watch: {
            activeSearch: function(val) {
                console.log(val);
            },
            topics: function(val) {
                this.topicList = val;
            },
            activeTopicId: function(val) {
                this.$refs[this.currentTab.activeComp].resetPage(val);
                this.$refs[this.currentTab.activeComp].getQuestions(val);
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "../../../assets/styles/_vars";
    #questions-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        .nav-tabs {
            li {
                .nav-link {
                    padding: 5px 45px 5px 25px;
                    .counter {
                        font-weight: 400;
                        margin-left: 10px;
                        position: absolute;
                    }
                }
            }
        }
        .search-filter {
            width: 100%;
            background: #fff;
            margin-bottom: 20px;
            border-radius: 5px;
        }
        .page-content {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-wrap: nowrap;
            /*width: 69.6%;
            padding-right: 20px;*/
            .component-wrapper {
                width: 69.6%;
                padding-right: 20px;
            }
            .add-personal-question {
                box-shadow: 0 0 6px -4px black;
                border-radius: 1px;
                background: #FFFFFF;
                padding: 20px 38px;
                margin-bottom: 20px;
                .component-wrapper {
                    width: 69.6%;
                    padding-right: 20px;
                }
                .btns-wrapper {
                    text-align: left;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    .plus-btn {
                        font-size: 26px;
                        font-weight: bold;
                        margin-right: 25px;
                        color: @theme_color;
                        line-height: 0;
                    }
                    .title-btn {
                        font-size: 13px;
                        color: @theme_color;
                        text-transform: uppercase;
                        font-weight: 500;
                    }
                }
            }
        }
    }

    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 576px) {
        #questions-wrapper {
            #nav {
                flex-wrap: nowrap;
                overflow-x: auto;
                overflow-y: hidden;
                &::-webkit-scrollbar{
                    height: 2px!important;
                    width: 2px!important;
                }
                li {
                    a {
                        position: relative;
                        margin-bottom: 4px;
                        padding: 5px 45px 0 25px;
                    }
                }
            }
            .page-content {
                flex-wrap: wrap-reverse;
                .add-personal-question {
                    padding: 10px 12px;
                    .btns-wrapper {
                        span {
                            &:first-child {
                                font-size: 22px;
                                margin-right: 14px;
                            }
                        }
                    }
                }
                .component-wrapper {
                    width: 100%;
                    padding: 0;
                }
            }
        }
    }
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 768px) {}
    // Medium devices (tablets, less than 992px)
    @media (max-width: 991px) {}
    // Large devices (desktops, less than 1200px)
    @media (max-width: 1200px) {

    }
</style>

