<template>
    <div id="questions-wrapper">
        <ul id="nav" class="nav nav-tabs mb-3">
            <li
                    v-for="tab in tabs"
                    :key="tab.name"
                    :class="['nav-item', { active: currentTab.name === tab.name }]"
                    @click="changeTab(tab)">
                <a class="nav-link" :class="[{ active: currentTab.name === tab.name }]">
                    <span>{{ tab.name }}</span>
                    <span class="counter" v-if="tab.id === 1 && topic_list[0]">{{ topic_list[0].count_not_answered}}</span>
                    <span class="counter" v-if="tab.id === 2 && topic_list[0]">{{ topic_list[0].count_all}}</span>
                    <span class="counter" v-if="tab.id === 3 && topic_list[0]">{{ topic_list[0].count_answered}}</span>
                </a>
            </li>
        </ul>
        <search-filter :show-error="true" :preload-data="preloadSearchData" :placeholderText="'Поиск вопросов'" :items="[]" @filtered="filteredList" class="search-filter" :is-server-side="true" @onChange="onSearchChange" ref="search"></search-filter>

        <div class="page-content">
            <div class="component-wrapper">
                <component :is="currentTab.activeComp" :ref="currentTab.activeComp" :topicId="activeTopicId" :searchStr="search_str" :activeSearch="active_search"></component>
            </div>
            <right-sidebar :topicList="topic_list" :activeTab="currentTab.id" :questionType="9"></right-sidebar>
        </div>
        <!--<div class="page-content" v-if="currentTab.id === 1">
            <open-questions ref="open_questions" :topicId="activeTopicId" :searchStr="search_str"></open-questions>
        </div>
        <div class="page-content" v-if="currentTab.id === 2">
            <all-questions ref="all_questions" :topicId="activeTopicId" :searchStr="search_str"></all-questions>
        </div>
        <div class="page-content" v-if="currentTab.id === 3">
            <closed-questions ref="closed_questions" :topicId="activeTopicId" :searchStr="search_str"></closed-questions>
        </div>

        <right-sidebar :topicList="this.topic_list" :activeTab="currentTab.id" :questionType="9"></right-sidebar>-->
    </div>
</template>

<script>
    import searchFilter from '../../../components/_shared/SearchFilter';
    import QuestionsRightSidebar from "../../../components/questions/QuestionsRightSidebar";
    import AllQuestions from "../../../components/questions/questions-of-the-day/all";
    import ClosedQuestions from "../../../components/questions/questions-of-the-day/closed";
    import OpenQuestions from "../../../components/questions/questions-of-the-day/open";
    import EventBus from "../../../_helpers/event-bus";

    export default {
        name: 'questions-of-the-day',
        components: {
            'right-sidebar': QuestionsRightSidebar,
            searchFilter,
            'all-questions': AllQuestions,
            'closed-questions': ClosedQuestions,
            'open-questions': OpenQuestions
        },
        data () {
            return {
                tabs: [
                    {
                        name: 'Неотвеченные',
                        id: 1,
                        activeComp: 'open-questions'
                    }, {
                        name: 'Все',
                        id: 2,
                        activeComp: 'all-questions'
                    }, {
                        name: 'Отвеченные',
                        id: 3,
                        activeComp: 'closed-questions'
                    }
                ],
                currentTab: null,
                topic_list: [],
                search_str: '',
                active_search: false,
                preloadSearchData: {search_str: '', active: false}
            }
        },
        created() {
            if (this.activeSearch.str.length > 0) {
                this.search_str = this.activeSearch.str;
                this.active_search = this.activeSearch.active;
                this.preloadSearchData = {search_str: this.activeSearch.str, active: this.activeSearch.active};
            }
            (this.$route.query.tab !== undefined) ? this.changeTab(this.tabs[this.$route.query.tab]) : this.changeTab(this.tabs[0]);
            //this.loadListenerOn();
        },
        mounted () {
            EventBus.$on('loadMoreQuestions', (data) => {
                console.log('LISTENER ON');
                this.$refs[this.currentTab.activeComp].getQuestions(this.activeTopicId);
            });
            this.getTopicList();
        },
        beforeDestroy() {
            EventBus.$off('loadMoreQuestions');
            //this.loadListenerOff();
        },
        computed: {
            activeSearch() {
                return this.$store.state.question.search;
            },
            topicList() {
                return this.$store.state.question.topic_list_qotd;
            },
            activeTopicId() {
                return this.$store.state.question.active_qotd_topic_id;
            }
        },
        methods: {
            changeTab(tab) {
                this.currentTab = tab;
                /*if (this.$refs.search) {
                    this.$refs.search.remoteClearSearch(true);
                }*/
                setTimeout(() => {
                    if (this.$refs.search.activeSearch && this.search_str.length > 0) {
                        console.log('_______________________________________________________________________________________');
                        this.$refs[this.currentTab.activeComp].resetPage(this.activeTopicId);
                        this.$refs[this.currentTab.activeComp].searchQuestions(this.activeTopicId, this.search_str);
                    }
                }, 0);
                this.$forceUpdate();
            },
            onSearchChange(data) {
                this.search_str = data;
                this.$store.dispatch('question/setSearch', {active: this.active_search, str: data});
            },
            filteredList (data) {
                this.search_str = data.searchStr;
                this.$refs[this.currentTab.activeComp].resetPage(this.activeTopicId);
                if (this.search_str.length > 0) {
                    this.active_search = true;
                    this.$store.dispatch('question/setSearch', {active: this.active_search, str: this.search_str});
                    this.$refs[this.currentTab.activeComp].searchQuestions(this.activeTopicId, this.search_str);
                } else {
                    this.active_search = false;
                    this.$store.dispatch('question/setSearch', {active: this.active_search, str: this.search_str});
                    this.$refs[this.currentTab.activeComp].getQuestions(this.activeTopicId);
                }

            },
            getTopicList() {
                this.$store.dispatch('question/getTopics', 9);
            },
        },
        watch: {
            activeSearch: function(val) {
                console.log(val);
            },
            topicList: function(val) {
                this.topic_list = val;
            },
            activeTopicId: function(val) {
                this.$refs[this.currentTab.activeComp].resetPage(val);
                this.$refs[this.currentTab.activeComp].getQuestions(val);
            }
        }
    }
</script>

<style lang="less" scoped>
    #questions-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        padding-bottom: 100px;
        .nav-tabs {
            li {
                .nav-link {
                    padding: 5px 45px 5px 25px;
                    .counter {
                        font-weight: 400;
                        margin-left: 10px;
                        position: absolute;
                    }
                }
            }
        }
        .search-filter {
            width: 100%;
            background: #fff;
            margin-bottom: 20px;
            border-radius: 5px;
        }
        .page-content {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-wrap: nowrap;
            /*width: 69.6%;
            padding-right: 20px;*/
            .component-wrapper {
                width: 69.6%;
                padding-right: 20px;
            }
        }
    }

    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 576px) {
        #questions-wrapper {
            #nav {
                flex-wrap: nowrap;
                overflow-x: auto;
                overflow-y: hidden;
                &::-webkit-scrollbar{
                    height: 2px!important;
                    width: 2px!important;
                }
                li {
                    a {
                        position: relative;
                        margin-bottom: 4px;
                        padding: 5px 45px 0 25px;
                    }
                }
            }
            .page-content {
                flex-wrap: wrap-reverse;
                .component-wrapper {
                    width: 100%;
                    padding: 0;
                }
            }
        }
    }
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 768px) {}
    // Medium devices (tablets, less than 992px)
    @media (max-width: 991px) {}
    // Large devices (desktops, less than 1200px)
    @media (max-width: 1200px) {}
</style>
