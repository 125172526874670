<template>
    <div id="questions" v-if="!loading">
        <DynamicScroller :items="list" :min-item-size="200" class="scroller" page-mode @update="handleScroll" :emitUpdate="true" :buffer="1000">
            <template v-slot="{ item, index, active }">
                <DynamicScrollerItem :item="item" :active="active" :data-index="index" :size-dependencies="[item]">
                    <QuestionPost :item="item" :list="list" block-title="Вопрос дня" :show-item="false" :has-delete="false" :topic-global-id="9" :show-date="true"></QuestionPost>
                </DynamicScrollerItem>
            </template>
        </DynamicScroller>
    </div>
</template>

<script>
    import {questionsService} from "../../../_api/questions.service";
    import QuestionPost from "../../feed/QuestionPostBody";
    import {parsePostBody} from "../../../_helpers/post-body-parser";
    import moment from "moment";

    export default {
        name: 'closed-questions',
        props: {
            topicId: Number,
            searchStr: String,
            activeSearch: Boolean
        },
        data () {
            return {
                loading: true,
                list: [],
                loadIndexThreshold: 15,
                loadIndex: 15,
                page: 0
            }
        },
        components: {
            QuestionPost
        },
        computed: {},
        methods: {
            handleScroll(startIndex, endIndex) {
                if (endIndex === this.loadIndex) {
                    this.loadIndex += this.loadIndexThreshold;
                    (!this.searchStr.length) ? this.getQuestions(this.topicId) : this.searchQuestions(this.topicId, this.searchStr);
                }
            },
            getQuestions(top_id) {
                questionsService.getClosedQOTDQuestions({
                    page: (!this.page) ? 1 : this.page + 1,
                    topic_id: top_id !== 9999999 ? top_id : null
                }).then((res) => {
                    if (res) {
                        res.data.forEach(obj => {
                            parsePostBody(obj).then(res_q => {
                                res_q['title'] = 'Вопрос дня';
                                res_q['has_answer'] = res_q.answers.some(i => {
                                    if (i.checked) {
                                        res_q['selected'] = i;
                                    }
                                    return i.checked;
                                });
                                res_q['has_answer_mode'] = res_q['has_answer'];
	                            if (res_q.dates_of_questions_the_day.length) {
		                            res_q['dates_if_the_day_preview'] = res_q.dates_of_questions_the_day.sort((a,b) => new Date(moment(b.date)).getTime() - new Date(moment(a.date)).getTime()).map((obj) => moment(obj.date, 'yyyy-mm-DD').format('DD.mm.yyyy')).filter((y, index) => index < 2).join(', ');
		                            res_q['dates_if_the_day_full'] = res_q.dates_of_questions_the_day.sort((a,b) => new Date(moment(b.date)).getTime() - new Date(moment(a.date)).getTime()).map((obj) => moment(obj.date, 'yyyy-mm-DD').format('DD.mm.yyyy')).filter((y, index) => index > 2).join(', ');
	                            }
                                this.list.push(res_q);
                            });
                        });
                        this.page = res.current_page;
                        this.$forceUpdate();
                    }
                    this.loading = false;
                });
            },
            searchQuestions(top_id, search_str) {
                questionsService.searchQuestionsAPI({
                    page: (!this.page) ? 1 : this.page + 1,
                    perPage: 10,
                    topic_id: top_id !== 9999999 ? top_id : null,
                    query: search_str,
                    type: 'closed', // opened/closed/blank if all,
                    is_day: 1
                }).then((res) => {
                    console.log(res);
                    if (res) {
                        res.data.forEach(obj => {
                            parsePostBody(obj).then(res_q => {
                                res_q['title'] = 'Вопрос дня';
                                res_q['has_answer'] = res_q.answers.some(i => {
                                    if (i.checked) {
                                        res_q['selected'] = i;
                                    }
                                    return i.checked;
                                });
                                res_q['has_answer_mode'] = res_q['has_answer'];
	                            if (res_q.dates_of_questions_the_day.length) {
		                            res_q['dates_if_the_day_preview'] = res_q.dates_of_questions_the_day.sort((a,b) => new Date(moment(b.date)).getTime() - new Date(moment(a.date)).getTime()).map((obj) => moment(obj.date, 'yyyy-mm-DD').format('DD.mm.yyyy')).filter((y, index) => index < 2).join(', ');
		                            res_q['dates_if_the_day_full'] = res_q.dates_of_questions_the_day.sort((a,b) => new Date(moment(b.date)).getTime() - new Date(moment(a.date)).getTime()).map((obj) => moment(obj.date, 'yyyy-mm-DD').format('DD.mm.yyyy')).filter((y, index) => index > 2).join(', ');
	                            }
                                this.list.push(res_q);
                            });
                        });
                        if (res.data.length) {
                            this.page += 1;
                        }
                    }
                    this.loading = false;
                    // console.log(this.listOfOpenQuestions);
                });
            },
            resetPage(id) {
                this.page = 0;
                this.list = [];
                this.loadIndex = this.loadIndexThreshold;
            }
        },
        created() {
            if (!this.activeSearch) {
                this.getQuestions(this.topicId);
            }
        },
        watch: {}
    }
</script>

<style lang="less" scoped>
    #questions {}
</style>

