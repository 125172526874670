<template>
    <div id="questions" v-if="!loading">
        <DynamicScroller :items="list" :min-item-size="200" class="scroller" page-mode @update="handleScroll" :emitUpdate="true" :buffer="1000">
            <template v-slot="{ item, index, active }">
                <DynamicScrollerItem :item="item" :active="active" :data-index="index" :size-dependencies="[item]">
                    <QuestionPost :item="item" :list="list" block-title="Персональный вопрос" :has-delete="true" :has-edit="true" :topic-global-id="8" :show-item="false" :has-margin="0" :delete-after-submit="false" :is-feed="false" :read-only="false" :show-avatars="true" :show-title-avatar="true" @onItemEdit="onItemEdit"></QuestionPost>
                </DynamicScrollerItem>
            </template>
        </DynamicScroller>
    </div>
</template>

<script>
    import {questionsService} from "../../../_api/questions.service";
    import QuestionPost from "../../feed/QuestionPostBody";
    import {parsePostBody} from "../../../_helpers/post-body-parser";

    export default {
        name: 'my-pq',
        props: {
            searchStr: String,
            topicId: Number,
            activeSearch: Boolean
        },
        data () {
            return {
                loading: true,
                list: [],
                loadIndexThreshold: 10,
                loadIndex: 10,
                page: 0,
                deleteEdit: true
            }
        },
        components: {
            QuestionPost
        },
        computed: {
            user () {
                return this.$store.state.authentication.user;
            },
            user_avatar () {
                return this.$store.state.authentication.user_avatar;
            },
        },
        methods: {
            handleScroll(startIndex, endIndex) {
                if (endIndex === this.loadIndex) {
                    this.loadIndex += this.loadIndexThreshold;
                    (!this.searchStr.length) ? this.getQuestions(this.topicId) : this.searchQuestions(this.topicId, this.searchStr);
                }
            },
            getQuestions(top_id) {
                questionsService.getPersonalQuestions({
                    page: (!this.page) ? 1 : this.page + 1,
                    topic_id: top_id !== 9999999 ? top_id : null
                }).then((res) => {
                    if (res) {
                        res.data.forEach(obj => {
                            parsePostBody(obj).then(res_q => {
                                res_q['title'] = 'Персональный вопрос';
                                res_q['has_answer'] = res_q.answers.some(i => {
                                    if (i.checked) {
                                        res_q['selected'] = i;
                                    }
                                    return i.checked;
                                });
                                res_q['bg_image'] = this.user_avatar;
                                res_q['avatar_parsed'] = this.user_avatar;
                                res_q['has_answer_mode'] = res_q['has_answer'];
                                this.list.push(res_q);
                            });
                        });
                        console.log(this.list);
                        this.page = res.current_page;
                    }
                    this.loading = false;
                });
            },
            searchQuestions(top_id, search_str) {
                questionsService.searchPersonalQuestionsAPI({
                    page: (!this.page) ? 1 : this.page + 1,
                    perPage: 10,
                    topic_id: top_id !== 9999999 ? top_id : null,
                    query: search_str,
                    type: null, // opened/closed/blank if all,
                    is_my: 1
                }).then((res) => {
                    // console.log(res);
                    if (res) {
                        res.data.forEach(obj => {
                            parsePostBody(obj).then(res_q => {
                                res_q['title'] = 'Персональный вопрос';
                                res_q['has_answer'] = res_q.answers.some(i => {
                                    if (i.checked) {
                                        res_q['selected'] = i;
                                    }
                                    return i.checked;
                                });
                                res_q['has_answer_mode'] = res_q['has_answer'];
                                this.list.push(res_q);
                            });
                        });
                        if (res.data.length) {
                            this.page += 1;
                        }
                    }
                    this.loading = false;
                    // console.log(this.listOfOpenQuestions);
                });
            },
            resetPage() {
                this.page = 0;
                this.list = [];
                this.loadIndex = this.loadIndexThreshold;
            },
            onItemEdit(data) {
                // console.log(data);
                this.$emit('onItemEdit')
            },
            getPersonalQuestionsAskedByUser() {
                questionsService.getPersonalQuestionsAskedByUser({
                    user_id: this.user_data.id,
                    page: (!this.page) ? 1 : this.page + 1,
                }).then((res) => {
                    console.log(res);
                    this.listOfPQ.push(res.data);
                    this.page = res.current_page;
                    this.$forceUpdate();
                });
            },
        },
        created() {
            if (!this.activeSearch) {
                this.getQuestions(this.topicId);
            }
        },
        watch: {}
    }
</script>

<style lang="less" scoped>
    #questions {
        &.is-modal {
            max-height: 80vh;
            overflow-y: auto;
        }
        .modal-body {
            background: #f4f4f4;
        }
    }
</style>

