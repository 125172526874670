<template>
    <div id="questions">
        <transition name="fade">
            <router-view/>
        </transition>
    </div>
</template>

<script>
    export default {
        name: 'questions',
        data () {
            return {}
        },
        created() {}
    }
</script>

<style lang="less" scoped></style>

